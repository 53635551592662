@import "src/assets/scss/theme.scss";

.label {
  @include text-500;
  color: $gray-600;
  margin-bottom: 0;
  font-size: 14px;
}

.text {
  @include text-600;
  font-size: 14px;
  color: $gray-600;
}

.defaultDisplay {
  @include text-600;
  font-size: 14px;
  color: $gray-300;
}
