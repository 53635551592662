@import "src/assets/scss/theme.scss";
@import "./Dashboard.module.scss";

.dashboardCard {
  padding: $dashboard-padding;
  h2 {
    margin-top: 0px;
    margin-bottom: 8px;
    @include h700();
  }
  .loadingContainer {
    display: flex;
    justify-content: center;
    .loadingCircle {
      align-self: center;
      color: $primary-700;
    }
  }

  .error {
    color: $red-700;
  }
}
