@import "src/assets/scss/theme.scss";

.button {
  font-size: 14px;
  border-radius: 8px;

  @media (min-width: $breakpoint-sm) {
    height: 48px;
  }
}

.footerText {
  @include text-300;
  font-size: 12px;
  margin-bottom: 0px;
  max-width: 450px;
  color: $gray-600;
  margin-top: 16px;
}

.loanAcknowledgement {
  margin-top: 24px;
}

.achText {
  @include text-600;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 0;
}

.executeText {
  @include text-400;
  font-size: 12px;
  color: $gray-600;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $gray-50;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 0;
  padding-bottom: 8px;
  border-radius: 6px;
  border: 1px solid $gray-200;

  @media (min-width: $breakpoint-sm) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px;
    padding-bottom: 16px;
  }

  &__primary {
    background-color: $primary-50;
    border: 1px solid $primary-200;
  }
}

.totalCost {
  @include h400;
  font-size: 14px;
  font-weight: 700;
  color: $gray-800;
  margin: 0px;
}

.infoIcon {
  margin-left: 6px;
}

.btcHeader {
  color: $gray-500;
  margin-bottom: 0;
  font-size: 20px;

  &__flashPrimary {
    @include flashIn(1250ms);
  }

  &__primary {
    color: $primary-700;
  }
}

.destination {
  color: $gray-500;
  margin-bottom: 0;
  font-size: 14px;
}
