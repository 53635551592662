body {
  background-color: #fff;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="date"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

button:focus {
  outline: none;
}

.carousel {
  & > .carousel-control-prev > .carousel-control-prev-icon {
    background-image: none;

    &::before {
      content: "\f190";
      display: block;
      font: normal normal normal 28pt/1 FontAwesome;
      color: black;
    }
  }
  & > .carousel-control-next > .carousel-control-next-icon {
    background-image: none;

    &::before {
      content: "\f18e";
      display: block;
      font: normal normal normal 28pt/1 FontAwesome;
      color: black;
    }
  }
}
