.container {
  justify-content: space-between;
  position: relative;
  max-width: 800px;
}

.smTitle {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.selectContainer {
  display: flex;
  justify-content: center;
}

.buttonWrapper {
  max-width: 17rem;
  min-width: 17rem;
  font-size: 12px;
}

.menu {
  position: absolute;
}

.smMenu {
  composes: menu;
  top: 8px;
  right: -16px;
}

.lgMenu {
  composes: menu;
  right: 48px;
  top: 13px;
}
