/* From https://raw.githubusercontent.com/twbs/bootstrap/v4.0.0-alpha.6/docs/examples/dashboard/dashboard.css */

/*
 * Typography
 */

span.copyable {
  cursor: pointer;
}

h1 span.uuid {
  font-weight: normal;
  text-transform: none;
}

div.divider {
  background-color: #008abd; /* brand primary */
  min-height: 1em;
  width: 100%;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -khtml-border-radius: 3px;
  border-radius: 3px;
  margin-top: 1em;
  margin-bottom: 1.5em;
}

/*
 * Header
 */

#navbar-loans-dropdown {
  width: 7rem;
}

/*
 * Tables
 */

/* table thead { */
/*     background-color: #008ABD; */
/*     color: #fff; */
/* } */

/*
 * Vault
 */

.vault {
  background-color: rgba(0, 138, 189, 0.1);
}

.vault p.balance-confirmed {
  margin-bottom: 0em;
}

.vault p.balance-confirmed-label {
  margin-bottom: 1em;
}

.vault p.balance-pending {
  margin-bottom: 0em;
}

.vault p.balance-pending-label {
  margin-bottom: 2em;
}

/*
 *
 */

.lending-map {
  width: 100%;
}
