@import "src/assets/scss/theme.scss";

.container {
  display: flex;
  justify-content: center;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 87.5rem;
  margin-left: 5rem;
  margin-right: 5rem;
  flex: 1;
}

.table {
  margin-top: 0.25rem;
  background-color: $white;
}

.cloudIcon {
  font-size: 1.125rem;
}

.tableHeader {
  @include h200;
  padding-bottom: 1.25rem;
  padding-left: 0.625rem;
  text-transform: uppercase;
  &_alignRight {
    text-align: right;
    padding-right: 0;
  }
}

.tableItem {
  color: $gray-600;
  padding-left: 0.625rem;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;

  &:last-of-type {
    padding: 0.35rem;
    color: $gray-600;
  }
}

.emptyState {
  height: 14.25rem;
  width: 100%;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  @include text-500;
  color: $gray-600;
}

.toast {
  background-color: $white;
  z-index: 1;
}
