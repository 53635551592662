@import "src/assets/scss/theme.scss";

.container {
  position: relative;
}

.icon {
  font-size: 14px;
  cursor: pointer;
}

.badge {
  @include h300;
  font-size: 14px;
  border-radius: 16px;
  background-color: $primary-100;
  color: $primary-700;
  padding: 4px 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: 14px;
  left: -26px;
  cursor: default;

  &_hide {
    display: none;
    opacity: 0;
  }

  &_fadeIn {
    animation: fadeIn 200ms;
    animation-fill-mode: forwards;
  }

  &_fadeOut {
    animation: fadeOut 500ms;
    animation-fill-mode: forwards;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
