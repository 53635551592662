// From https://bootsnipp.com/snippets/featured/process-steps

.stepwizard {
  display: table;
  width: 100%;
  position: relative;

  .stepwizard-row {
    display: table-row;
    &:before {
      top: 14px;
      bottom: 0;
      position: absolute;
      content: " ";
      width: 100%;
      height: 1px;
      background-color: $gray-400; //adjust this to change the line color
    }

    .stepwizard-step {
      display: table-cell;
      text-align: center;
      position: relative;

      .btn-circle {
        width: 30px;
        height: 30px;
        text-align: center;
        padding: 6px 0;
        font-size: 12px;
        line-height: 1.428571429;
        border-radius: 15px;
      }

      button[disabled] {
        opacity: 1 !important;
        filter: alpha(opacity=100) !important;
      }

      p {
        margin-top: 10px;
      }
    }
  }
}
