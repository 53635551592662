@import "src/assets/scss/theme.scss";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.2rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.tooltip {
  @include text-500;
  background-color: $white;
  padding: 0.5rem;
  border-radius: 4px;
  position: relative;
  top: 1rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  width: 95%;

  &_pending {
    top: 1.5rem;
    width: 87%;
  }
}

.awaitingCompletionButton {
  flex: 1;

  &:hover {
    background-color: inherit;
  }
}

.label {
  @include text-600;
  color: $gray-600;
  margin-left: 0.5rem;

  &_disabled {
    color: $gray-300;
  }

  &::first-letter {
    text-transform: capitalize;
  }
}

.infoIcon {
  font-size: 1.125rem;
  color: $gray-400;
  position: relative;
  top: -1px;
  margin-left: 0.5rem;
}

.completedIcon {
  position: relative;
  left: -2px;
  color: $green-600;

  &_disabled {
    color: rgba(58, 143, 33, 0.4);
  }
}

.emptyRadioButton {
  min-width: 1.25rem;
  height: 1.25rem;
  border-color: $gray-600;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  background-color: $white;

  &_disabled {
    border-color: $gray-300;
  }
}

.buttonContainer {
  margin-left: auto;
  color: $gray-400;
}

.secondaryButtonRoot {
  font-size: 0.875rem !important ; // !important used to override Dashboard button role font-size
  font-weight: 600;
  text-transform: capitalize;

  &_pending {
    color: $gray-300 !important ; // !important used to override Dashboard button role font-size;
  }

  &_fullyCompleted {
    color: $gray-600 !important ; // !important used to override Dashboard button role font-size;
  }

  &_disabled {
    color: $gray-300 !important ; // !important used to override Dashboard button role font-size;
  }
}
