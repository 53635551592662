// From https://codepen.io/chrisdpratt/pen/IAymB
.bs-callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid $light;
  border-left-width: 5px;
  border-radius: 3px;
  background-color: $white;

  & + .bs-callout {
    margin-top: -5px;
  }

  &.bs-callout-default {
    border-left-color: $gray-600;
    h4 {
      color: $gray-600;
    }
  }

  &.bs-callout-primary {
    border-left-color: $primary-700;
    h4 {
      color: $primary-700;
    }
  }

  &.bs-callout-success {
    border-left-color: $green-600;
    h4 {
      color: $green-600;
    }
  }
  &.bs-callout-danger {
    border-left-color: $red-700;
    h4 {
      color: $red-700;
    }
  }
  &.bs-callout-warning {
    border-left-color: $yellow-500;
    h4 {
      color: $yellow-500;
    }
  }
  &.bs-callout-info {
    border-left-color: $gray-500;
    h4 {
      color: $gray-500;
    }
  }

  h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }

  p:last-child {
    margin-bottom: 0;
  }

  code {
    border-radius: 3px;
  }
}
