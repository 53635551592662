@import "src/assets/scss/theme.scss";

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: $gray-100;
  padding: 1rem;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.accessTimeRoot {
  position: relative;
  bottom: 0.19rem;
  margin-top: 0;
}

.title {
  font-size: 20px;
  color: $gray-800;
  margin-top: 0;
  margin-left: 0.375rem;
}

.descriptionText {
  color: $gray-500;
  margin-bottom: 0;
  max-width: 402px;
  margin-top: 0.25rem;
  font-size: 14px;
}

.buttonRoot {
  font-size: 14px;
}
