@import "src/assets/scss/theme.scss";

.appOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: $app-overlay-z-index;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.54);
  transition: background-color 0.3s;
}

.fadeOutAndHideOnUnmount {
  position: absolute;
  height: 100%;
  z-index: $app-overlay-z-index;
  width: 100%;
  background-color: none;
  transition: background-color 0.3s;

  animation-name: hide;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

@keyframes hide {
  0% {
    height: 100%;
    width: 100%;
  }
  99% {
    height: 100%;
    width: 100%;
    z-index: $app-overlay-z-index;
  }
  100% {
    height: 0%;
    width: 0%;
    z-index: 0;
  }
}
