@import "src/assets/scss/theme.scss";

.container {
  padding: 24px;
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: $breakpoint-sm) {
    width: 450px;
  }
}

.loadingGraph {
  margin-bottom: 50px;
  height: 100px;
}
