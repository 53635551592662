@import "src/assets/scss/theme.scss";

@mixin scaledSizes($height, $width, $padding-top, $padding-bottom) {
  @include scaledItemSizes($height, $width, $padding-top, $padding-bottom, 0.7);
  @media (min-width: $breakpoint-xs) {
    @include scaledItemSizes($height, $width, $padding-top, $padding-bottom, 0.75);
  }
  @media (min-width: $breakpoint-sm) {
    @include scaledItemSizes($height, $width, $padding-top, $padding-bottom, 0.85);
  }
  @media (min-width: $breakpoint-md) {
    @include scaledItemSizes($height, $width, $padding-top, $padding-bottom, 1);
  }
  //@media(min-width: $breakpoint-xl) {
  //  @include scaledItemSizes($height, $width, $padding-top, $padding-bottom, 1);
  //}
}

.default:hover {
  background-color: $primaryLightHover;
}

.default {
  background-color: $white;
}

.active {
  background-color: $primary-700;
  color: $white;
  svg {
    fill: white;
  }

  .textContainer {
    color: $white;
  }
}

.active:hover {
  background-color: $primaryHover;
}

.container {
  padding: 0;
  border-radius: 2px;
  text-transform: unset;
  min-width: unset;

  .disabled {
    background-color: $primaryDisabled;
    color: $white;
  }
}

.container:focus {
  outline: 0;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;

  &.lgContainer {
    @include scaledSizes(162px, 132px, 33px, 24px);
    font-size: 1rem;
  }

  &.mdContainer {
    @include scaledSizes(121px, 96px, 24px, 16px);
  }

  &.smContainer {
    @include scaledSizes(106px, 82.7px, 20px, 18px);
  }

  &.xsContainer {
    height: 76px;
    width: 61.93px;
    padding-top: 14px;
    padding-bottom: 6px;
    font-size: 10px;
  }
}

.iconContainer {
  line-height: 1;
}

.logo {
  width: 50px;
  height: 50px;
  color: $gray-600;
}

.icon {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
}

.xsIcon {
  width: 16px;
  height: 16px;
}

.smIcon {
  width: 20px;
  height: 20px;
}

.mdIcon {
  width: 12px;
  height: 12px;

  @media (min-width: $breakpoint-xs) {
    width: 15px;
    height: 15px;
  }

  @media (min-width: $breakpoint-sm) {
    width: 20px;
    height: 20px;
  }

  @media (min-width: $breakpoint-xl) {
    width: 23px;
    height: 23px;
  }
}

.lgIcon {
  width: 15px;
  height: 15px;

  @media (min-width: $breakpoint-xs) {
    width: 18px;
    height: 18px;
  }

  @media (min-width: $breakpoint-sm) {
    width: 25px;
    height: 25px;
  }

  @media (min-width: $breakpoint-md) {
    width: 28px;
    height: 28px;
  }

  @media (min-width: $breakpoint-xl) {
    width: 30px;
    height: 30px;
  }
}

.image {
  width: auto;
}

.xsImage {
  height: 22px;
}

.smImage {
  height: 28px;
}

.mdImage {
  height: 32px;
}

.lgImage {
  height: 40px;
}

.textContainer {
  margin-top: auto;
}

.lgTextContainer {
  @include h600;
}

.smTextContainer {
  @include text-500;
}
