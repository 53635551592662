@import "src/assets/scss/theme.scss";

.disabled {
  background-color: $gray-100;
}

.muiInput {
  background-color: $white;
}

.noLabel {
  fieldset {
    legend {
      width: unset;
    }
  }
}

.input {
  @include text-500;

  &_bold {
    @include h600;
  }

  &_mono {
    @include mono-300;
    font-size: 1rem;
  }
}
