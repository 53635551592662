// Removed along with bootstrap
$spacer: 1rem;

.card {
  margin-top: 1em;
  background-color: $white;
  color: $gray-800; //off-black

  h6 {
    margin-bottom: 0.5em;
  }
  .card-block {
    padding: $spacer $spacer $spacer * 0.5;
  }

  &.card-outline-primary {
    border-color: $primary-700;
  }
  &.card-outline-secondary {
    border-color: $gray-800;
  }
  &.card-outline-info {
    border-color: $gray-500;
  }
  &.card-outline-success {
    border-color: $green-600;
  }
  &.card-outline-warning {
    border-color: $yellow-500;
  }
  &.card-outline-danger {
    border-color: $red-700;
  }
}
