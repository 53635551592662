@import "src/assets/scss/theme.scss";

.messageContents {
  flex-wrap: nowrap;
}

.success {
  composes: messageContents;
  background-color: $green-600;
}

.error {
  composes: messageContents;
  background-color: $red-700;
}

.info {
  composes: messageContents;
  background-color: $primary-700;
}

.warning {
  composes: messageContents;
  background-color: $yellow-500;
  color: $gray-800;
}

.icon {
  font-size: 1.1rem;
}

.messageIcon {
  margin-right: 0.5rem;
  opacity: 0.9;
}

.message {
  display: flex;
  align-items: center;
  font-weight: 300;
}

.positionPublic,
.positionAuthenticated {
  top: 1rem;
  left: 1rem;
}
