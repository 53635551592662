@import "src/assets/scss/theme.scss";
.modal {
  height: 100%;
  width: 100%;
  min-height: 300px;
  //max-height: 500px;
  min-width: 300px;
  max-width: calc(100vw - 49px - 2rem);
  padding: 40px 16px;

  @media (min-width: $breakpoint-xs) {
    min-height: 300px;
    max-height: 1000px;
    min-width: 300px;
    max-width: 500px;
    padding: 32px 16px;
  }
  @media (min-width: $breakpoint-sm) {
    min-height: 450px;
    max-height: 1200px;
    min-width: 550px;
    max-width: 600px;
    padding: 3rem 2.5rem;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.header {
}
