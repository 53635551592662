@import "src/assets/scss/theme.scss";

.collapsibleContainer {
  height: 7.3rem;
  animation: collapseAnimation 600ms linear forwards;

  @media (max-width: 1001px) {
    height: 14.4rem;
    animation: collapseAnimation 600ms linear forwards;
  }
}
@keyframes collapseAnimation {
  to {
    height: 0;
  }
}
