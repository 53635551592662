@import "src/assets/scss/theme.scss";

.errorMessage {
  padding-bottom: 1em;
  text-align: center;
}

.publicApp {
  composes: errorMessage;
  color: $white;
  margin-top: 0;
}

.privateApp {
  composes: errorMessage;
  margin-top: 15em;
  color: $white;
}
