@import "src/assets/scss/theme.scss";

.crypto {
  @include text-500;
  display: block;
}

.fiat {
  @include text-500("light");
  font-size: calc(0.97 * 14px);
  padding-left: 14px;
  display: block;
}

.container {
  display: flex;
  align-items: flex-start;
}
