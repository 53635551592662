@import "src/assets/scss/theme.scss";

.input {
  @include text-600("light");
  height: 2.75rem;
  padding: 0;
}

.muiInput {
  background-color: $white;
  height: 2.75rem;
  padding-left: 0.75rem;

  ::placeholder {
    color: $gray-500;
  }

  fieldset {
    height: 2.75rem;
    inset: 0;
  }

  legend {
    display: none;
  }
}

.returnInput {
  @include mono-300;
  height: 2.75rem;
  padding-top: 0;
  padding-bottom: 0;
}

.menuItem {
  @include text-300("light");
}

.menuItemLabel {
  @include text-300("light");
  margin-right: 0.3125rem;
}
