.smTitle {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.content {
  font-size: 1rem;
}

.contentWithButton {
  //display: inline-flex;
  //align-content: center;
  //flex-wrap: wrap;
}

.signButton {
  text-transform: unset;
  font-weight: 400;
  font-size: 1rem;
}

.cancelButton {
  composes: signButton;
  padding: 0 0.1rem;
  line-height: 1.5;
  vertical-align: baseline;
}
