@import "src/assets/scss/theme.scss";

.signedKeyList {
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.signedKeyRow {
  margin-top: 16px;
}

.leftContainer {
  margin-top: 4px;
}

.broadcastHeading {
  text-align: center;
}

.broadcastText {
  text-align: center;
}

.loadingButton {
  // the size is written inline for mui v3 icons so need !important to override
  // FIXME: MUI-V4 upgrade - Remove !important flags
  width: 20px !important;
  height: 20px !important;
  color: $white;
}

.error {
  color: $red-700;
}
