.filePreview {
  min-width: 100%;

  > div:last-child {
    min-width: 100%;
    margin: 0.5rem 0;

    // Main preview box container
    > div:first-child {
      min-width: 100%;
      margin-right: 0;
      margin-left: 0;
      position: relative;

      // Preview box contents
      > div:first-child {
        margin-left: 0;
        min-width: 100% !important;
        margin-right: 0;
        text-align: center;
      }

      // Trash icon for delete
      > div:last-child {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
      }
    }
  }
}
