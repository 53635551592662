@import "src/assets/scss/theme.scss";

.memberHeaderRow {
  border-bottom: 2px black solid;
  font-weight: bold;
}

.memberRow {
  padding-bottom: 1rem;
  margin-top: 1rem;
  border-bottom: 1px black solid;
}

.lastMemberRow {
  margin-top: 1rem;
}

.removeMemberButton {
  color: red;
}

.addMembersButton {
  margin-top: 3rem;
}

.markedForRemoval {
  background-color: rgba(0, 0, 0, 0.08);
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
  a {
    color: $gray-500;
    text-decoration: none;
  }
  text-decoration: line-through;
}
