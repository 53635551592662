@import "src/assets/scss/theme.scss";

.tooltip {
  @include text-500;
  font-size: 14px;
  padding: 8px;
  border-radius: 4px;
  max-width: 230px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.title {
  @include h400;
}

.infoIcon {
  font-size: 18px;
  color: $gray-400;
  position: relative;
  top: -1px;
}
