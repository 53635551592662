@import "src/assets/scss/theme.scss";

.cellDefaults {
  @include text-500;
  border: 1px solid rgba(224, 224, 224, 1);
  padding: 6px 8px;
  height: 48px;

  @media (min-width: $breakpoint-sm) {
  }

  @media (min-width: $breakpoint-md) {
    padding: 4px 16px;
  }
}

.td {
  composes: cellDefaults;
  &:last-child {
    padding-right: 16px;
  }
  line-height: 1;
}

.th {
  composes: cellDefaults;
  @include text-600;
  background-color: $primaryLightHover;
  width: 72px;
  min-width: 72px;

  @media (min-width: $breakpoint-sm) {
    width: 80px;
    min-width: 80px;
  }

  @media (min-width: $breakpoint-md) {
    width: 130px;
    min-width: 130px;
  }
}

.splitCell {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: none;
  border-bottom: none;
  border-left: none;
}
