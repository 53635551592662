@import "src/assets/scss/theme.scss";

.muiInput {
  background-color: $white;

  &_padded {
    padding-left: 0.75rem;
  }

  ::placeholder {
    color: $gray-300;
  }

  label {
    transform: translate(14px, 11px);
  }
}

.input {
  @include h600;
  height: 2.75rem;
  padding: 0;
}

.maxButton {
  @include h100;
  color: $gray-300;
  cursor: pointer;

  &_enabled {
    color: $primary-700;
  }
}

.unit {
  @include h200;
}
