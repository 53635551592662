@import "src/assets/scss/theme.scss";

.uploadIcon {
  text-align: center;
  margin-bottom: 0.2rem;
}

.dropzone {
  height: 6rem;
  border-width: 2px;
  border-radius: 20px;
  border-color: $gray-500;
  border-style: dashed;
  padding: 1.2rem 2.4rem;
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: pointer;
}

.dropzoneDull {
  composes: dropzone;
  border-color: $gray-300;
  color: $gray-300;
  cursor: default;
}

.uploadContainer {
  margin: 0.5rem 0;
}
