/* Adapted from https://github.com/posabsolute/redux-flash-notification/blob/master/src/component/growler.scss */
.flash {
  position: inherit !important;
  z-index: 1000 !important;
  height: 4em !important;
  padding-top: 1.25em !important;
}

.flash {
  position: fixed;
  display: block;
  top: 5em;
  left: 0;
  opacity: 0;
  width: 100%;
  z-index: 999999;
  color: #fff;
  font-weight: bold;
  height: 3em;
  margin-top: -3em;
  padding-top: 0.75em;
}

.flash p {
  font-weight: bold;
}

.flash-success {
  opacity: 1;
  margin-top: 0em;
  background-color: #69932b; /* brand-success */
}

.flash-error {
  opacity: 1;
  margin-top: 0em;
  background: #e55934; /* brand-danger */
}
