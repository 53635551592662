@import "src/assets/scss/theme.scss";

.approval {
  display: grid;
  grid-template-columns: 1fr 20fr 7fr;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 0;

  &_empty {
    grid-template-columns: 1fr 27fr;
  }
}

// The margin-bottom is to line up the approval line with the approval index
.emptyApprovalLine {
  height: 1px;
  background-color: $gray-700;
  align-self: flex-end;
  margin-bottom: 0.3125rem;
}

.approvalName {
  overflow: ellipsis;
  font-weight: 500;
  color: $gray-700;
}

.actions {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.n {
  color: $gray-500;
}
