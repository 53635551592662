@import "src/assets/scss/theme.scss";

.label {
  @include text-500;
  font-size: 14px;
}

.amount {
  @include text-600;
  font-size: 14px;

  &_error {
    color: $red-700;
  }

  &_btc {
    @include text-400;
    font-size: 0.875rem;
  }
}

.infoIcon {
  color: $gray-400;
  font-size: 15px;
  margin-left: 0.25rem;
  margin-top: -2px;
}
