@import "src/assets/scss/theme.scss";

.uploadIcon {
  text-align: center;
}

.dropzone {
  height: 8rem;
  border-width: 2px;
  border-color: $gray-500;
  border-style: dashed;
  border-radius: 5px;
  margin-left: 2rem;
  margin-right: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.uploadContainer {
  margin: 0.5rem 0;
}

.preview {
  @extend .dropzone;
  padding: 1rem;
  margin-right: 1rem;
  background-color: $gray-200;
  .name {
    width: 100%;
    p {
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      width: 100%;
    }
  }
}

.acceptedFileExtensionsMessage {
  margin-top: 1rem;
  font-size: 0.7rem;
  color: $gray-500;
}
