.nonAuthedContent {
  min-height: calc(100vh - 100px);
  padding: 0.1px 1rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.publicApp {
  margin-top: 69px;
}

.fullPageContent {
  min-height: 100vh;
  width: 100%;
  padding: 0;
  display: flex;
}
