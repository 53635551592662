input.fillable {
  border: 0;
  border-bottom: 1px solid #000;
}

.signature {
  padding-top: 20px;
}

/* Placeholders */
.placeholders {
  padding-bottom: 3rem;
}

.placeholder img {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

button.btn-huge {
  margin-top: 2em;
  margin-bottom: 2em;
}
