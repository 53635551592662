@import "src/assets/scss/theme.scss";

.buyBitcoinButton {
  width: 100%;
  margin-top: 2.25rem;
}

.itemsContainer {
  margin-top: 2rem;
}
