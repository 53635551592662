// move content in front of close button when inside modal
button ~ .componentWrapper {
  .slideContainer {
    z-index: 101;
  }
}

.componentWrapper {
  position: relative;
}

.slideContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
}

.slideContent {
  width: 100%;
  height: 100%;
}

.issueWrapper {
  padding-top: 2rem;
}

.mainContent {
  button {
    position: static;
  }
}

.promptText:hover {
  cursor: pointer;
}
