@import "src/assets/scss/theme.scss";

.itemsContainer {
  margin-top: 2rem;
}

.tradingAgreementNote {
  @include text-300;
  color: $gray-600;
  margin-top: 0.55rem;
  margin-bottom: 0;
}

.pendingIcon {
  position: relative;
  left: 0.6rem;
  bottom: 0.187rem;
}

.title {
  color: $gray-800;
  margin-bottom: 0.25rem;
}

.minimumsBanner {
  background-color: $primary-50;
  border: 1px solid $primary-400;
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
}

.descriptionText {
  @include text-500;
  color: $gray-600;
  margin-bottom: 0.75rem;
}

.minimumsTitle {
  @include text-600;
  color: $gray-800;
  margin-bottom: 0.25rem;
}

.minimumsDescriptionText {
  @include text-500;
  color: $gray-600;
  margin-bottom: 0;
}
