@import "src/assets/scss/theme.scss";

.walletAfter {
  line-height: 1.1;
  margin-bottom: 0.2em;
  margin-top: 0.8em;
}

.smWalletBefore {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.lgWalletBefore {
  @include text-600;
  margin-bottom: 0.8rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  min-height: 12rem;
  margin-bottom: auto;
  padding: 0 0.5rem;
}

.mainMessage {
  @include text-600;
  margin-top: 1rem;
  margin-bottom: 0;
  max-width: 600px;
}

.exportIcon {
  font-size: 1.3em;
  align-self: center;
  margin-bottom: 0.15em;
}

.content {
  margin-bottom: 1rem;
  margin-top: 0.75rem;
  width: 26rem;
}

.smContent {
  margin-bottom: 0.5rem;
  margin-top: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.modalWrapper {
  padding-top: 1rem;
}

.modalExplanation {
  font-size: 1rem;
  margin-bottom: 0;

  @media (min-width: 425px) and (max-width: $breakpoint-sm) {
    font-size: 13px;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 16px;
  }
}

.ledgerMessageContainer {
  max-width: 430px;
}

.xpubHelperText {
  display: inline-block;
  padding-top: 4%;
}

.pathMatchText {
  padding-bottom: 0.5rem;
  display: inline-block;
}

.pathSeparator {
  padding-bottom: 0.2rem;
  display: inline-block;
}

.bottomWarning {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem !important;
}
