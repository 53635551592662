@import "src/assets/scss/theme.scss";

.secondaryButtonRoot {
  height: 37px;
  font-size: 14px;
}

.container {
  overflow-x: hidden; // overflow hidden allows for "inner modals" to slide in and out smoothly
  margin: 0;
}
