@import "src/assets/scss/theme.scss";

$dashboard-padding: 24px;

.header {
  @include h200("light");
}

.headerPadding {
  padding: 12px 0;
}

.linkColor {
  color: $primary-700;
}

.dashboardBody {
  @include text-500();
}

.buyBitcoinButton {
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 16px;
  margin-right: 16px;
}

.onboardingCard {
  z-index: $trading-highlight-z-index;
}
