@import "src/assets/scss/theme.scss";

.container {
  &:not(:first-of-type) {
    margin-top: 16px;
  }
}

.title {
  @include text-600;
  font-size: 14px;
  color: $gray-600;
  margin-bottom: 0;
}

.copyable {
  display: flex;
  align-items: baseline;
}

.content {
  @include text-500;
  font-size: 14px;
  color: $gray-600;
  margin: 0;
  margin-right: 8px;
  display: inline-block;
}

.copyablePopUpContainer {
  position: relative;
  display: inline;
  margin-left: 7px;
}
