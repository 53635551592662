.name {
  font-size: calc(1.05 * 14px);
  line-height: 1;
  margin-left: 8px;
  font-weight: 300;
}

.vaultIcon {
  display: block;
  margin-bottom: 2px;
  font-size: 14px;
  fill: rgba(0, 0, 0, 0.87);
}

.loanIcon {
  padding-top: 0.85px;
  font-size: 14.7px;
}

.container {
  display: flex;
  align-items: center;
}
