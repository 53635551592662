@import "src/assets/scss/theme.scss";

.clickableText {
  color: $primary-700;
  padding: 0;
  cursor: pointer;
}

.clickableText:hover {
  color: $primaryHover;
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-color: rgba($primary-700, 0.4);
}
