@import "src/assets/scss/theme.scss";

.muiInput {
  @include text-500;
  background-color: $white;
  height: 2.75rem;
  padding: 0 0 0 0.75rem;

  &_bold {
    @include h600;
  }

  &_disabled {
    background-color: $gray-100;
  }
}
