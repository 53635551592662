@import "src/assets/scss/theme.scss";

.input {
  @include text-600("light");
  height: 2.75rem;
  padding: 0;
}

.muiInput {
  background-color: $white;
  height: 2.75rem;
  padding-left: 0.75rem;
  color: $gray-500;

  ::placeholder {
    color: $gray-500;
  }

  fieldset {
    height: 2.75rem;
    inset: 0;
  }

  legend {
    display: none;
  }
}

.menuItem {
  @include text-600("light");
}
