@import "src/assets/scss/theme.scss";

.radioButton {
  margin-bottom: 17px;

  &_flush {
    margin-left: -0.5rem;
  }

  &_compact {
    margin-bottom: 0rem;
  }

  .icon {
    color: $gray-600;
    padding: 6px;

    &_primary {
      color: $primary-700;
    }

    &_secondary {
      color: $secondary;
    }

    &_disabled {
      color: $gray-300;
    }
  }

  .label {
    @include text-500;
    color: $gray-600;
    max-width: 300px;
    overflow-wrap: break-word;
    width: 100%;
    &_disabled {
      color: $gray-300;
    }
  }
}

.detailedRadioLabel {
  position: relative;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid $gray-400;
  background-color: $white;

  // for ripple effect
  position: relative;
  overflow: hidden;

  &_checked {
    background-color: $primary-100;
    transition: background-color ease-in-out 500ms;
    border-color: $primary-700;
    .detailedTitle {
      color: $primary-700;
    }
  }

  &_disabled {
    border-color: $gray-300;
    cursor: pointer;
    .detailedTitle,
    .detailedDescription {
      color: $gray-300;
    }
  }
}

.textContainer {
  // keeps the text above the ripple
  position: relative;
  z-index: 2;
}

.detailedTitle {
  @include text-600();
  margin-bottom: 4px;
  color: $gray-600;
}

.detailedDescription {
  @include text-300;
  margin-bottom: 0px;
}

// TODO: how can we make this reusable?
.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 300ms ease-in-out;
  background-color: $primary-100;
  left: -75%;
  top: -75%;
  width: 150%;
  height: 150%;
  z-index: 1;
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
