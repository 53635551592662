@import "src/assets/scss/theme.scss";

.muiInput_disabled {
  background-color: $gray-100;
}

.input {
  @include h600;
}

.unit {
  @include h400;
  color: $gray-500;

  &_disabled {
    color: $gray-300;
  }
}
