@import "src/assets/scss/theme.scss";

$menu-max-width: 382px;

.container {
  margin-top: 24px;
}

.select {
  @include text-600;
  background-color: $white;
  border: 1px solid $gray-300;
  font-size: 14px;
  color: $gray-500;

  &:focus {
    background-color: $white;
    border-bottom-color: none;
  }
}

.menuItem {
  display: block;
  width: 100%;
}

.menu {
  max-width: 382px;
}

.vaultLabel {
  @include text-300;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-width: 100%;
  font-size: 12px;
}

.label {
  margin-bottom: 0;
  font-size: 14px;
}

.selectEmpty {
  display: flex;
  justify-content: center;
}

.selectOption {
  display: flex;
  gap: 0.5rem;
}

.vaultName {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ctpInfo {
  display: flex;
  gap: 0.375rem;
  align-items: center;
}

.ctpIndicator {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;

  &_liquidation {
    background: $red-700;
  }

  &_marginCall {
    background: $red-500;
  }

  &_acceptable {
    background: $yellow-600;
  }

  &_healthy {
    background: $green-600;
  }

  &_excellent {
    background: $green-500;
  }
}

.outlineRoot {
  padding: 11px 10px;
  border-color: none;
  border-width: 0px;
  min-height: 39px;
}

.outLineInput {
  display: flex;
  align-items: center;
  padding: 0px 0px;
  border-color: none;
  border-width: 0px;
  height: 2.5rem !important;
  min-height: 40px !important;
}

.vaultId {
  @include h400;
  font-size: 14px;
}
