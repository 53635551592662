.button {
  padding: 6px;
  margin-bottom: 3px;
  margin-left: 4px;
}

.icon {
  font-size: 18px;
}

.txid {
  display: inline;
  line-height: 125%;
}
