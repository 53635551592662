.tracker {
  position: fixed;
  bottom: 15px;
  right: 60px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  z-index: 10000;
  font-weight: bold;
  border: 2px solid black;
}
