@import "@unchained/component-library/scss/theme.scss";

@import "./abstracts/z_indexes.scss";

/**********************
/ REMAINING VARIABLES
/**********************

/**
 * This file now exists to store non-canonical variables that are still in use.
 * The goal is to delete these over time.
 */

$primaryHover: rgba($primary-700, 0.9); // rgba(38,87,162,0.9);         /* main blue - lighter */
$secondary: $primary-300;
$primary: $primary-700;
$primaryDisabled: rgba(
  $primary-700,
  0.53
); //rgba(28, 87, 162, 0.53);       /* main blue - disabled */
$primaryLightHover: rgba($primary-700, 0.1); // rgba(38,87,162,0.1);       /* main blue - lighter */
$navbarBlue: #051b2f;
$partner: #9d6381;
$partnerHover: rgba($partner, 0.9); // rgba(157, 99, 129, 0.9);
$green800LightHover: rgba($green-700, 0.1);
$green800Disabled: rgba($green-700, 0.73);
$green800Hover: rgba($green-700, 0.85);
$partnerDisabled: rgba($partner, 0.53); // rgba(147, 99, 129, 0.53);
$greenLightHover: rgba($green-600, 0.1); // rgba(75, 127, 82, 0.1);   /* green - faint */
$light: #fbfcfe; /* off-white*/
$dark-blue: #051b31;
$dark-blue-gradient: #093159; /* used in SignedRibbon */

// For some use-cases during the update
$breakpoint-old-xl: 1400px;

/** borders **/
$border-radius: 8px; // TODO: Remove along with TW ContentSwitcher, which is deprecated and used only once
$light-border: 1px solid $gray-200; // TODO: Remove along with TW ContentSwitcher, which is deprecated and used only once

/** topbar **/
$topbar-height-xs: 4rem; // TODO: Remove along with Navigation refactor
$topbar-height-m: 5.125rem; // TODO: Remove along with Navigation refactor

/******
** Mixins
******/
@keyframes flashIn {
  0% {
    opacity: 0.5;
    color: $gray-600;
  }

  39% {
    opacity: 0.5;
    color: $gray-600;
  }

  40% {
    color: $primary-700;
    opacity: 1;
  }

  100% {
    color: $primary-700;
    opacity: 1;
  }
}

@mixin flashIn($timeInMs) {
  animation: flashIn $timeInMs;
}

@mixin scaledItemSizes($height, $width, $padding-top, $padding-bottom, $scaleFactor) {
  height: calc(#{$height} * #{$scaleFactor});
  width: calc(#{$width} * #{$scaleFactor});
  padding-top: calc(#{$padding-top} * #{$scaleFactor});
  padding-bottom: calc(#{$padding-bottom} * #{$scaleFactor});
}
