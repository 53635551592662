// Moved from trefoil private
@import "react-datepicker/dist/react-datepicker.css";
@import "react-table/react-table.css";

// These need to be defined before loading dependencies.
@import "theme";

// I was hoping the GlobalStyleWrap component would take care of this, but it doesn't seem to
@import "@unchained/component-library/index.css";

@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "@fortawesome/fontawesome-free/scss/brands";

@import "base/base";
@import "layout/forms";
@import "layout/footer";

@import "components/back_to_top";
@import "components/timeline";
@import "components/callouts";
@import "components/cards";

@import "../css/flash.css";
@import "../css/forms.css";
@import "../css/trefoil.css";

// Disable visibility of floating Osano cookie
.osano-cm-widget {
  display: none;
}

//
// Pinpad
//

.pinpad button {
  font-size: 8px;
}

span.pin {
  font-size: 20px;
}

span.pin i {
  padding-right: 5px;
}

//
// Slice Members
//

img.slice-key-logo {
  max-width: 20px;
  max-height: 20px;
}

.slice_members_signing .slice_member {
  padding: 10px;
}

.slice_members_signing .not_first {
  border-left-style: solid;
  border-left-color: #dfdfdf;
  border-left-width: 0.5px;
}

.slice_members_signing .chosen {
  background-color: rgba(0, 138, 189, 0.1); // $primary-700
}

//
// Custody Models
//

.custody-model-diagram img {
  max-width: 32px;
}

.custody-model-diagram .top {
  margin-left: 16px;
}

//
// Hardware Walelts
//

.hardware_wallet_type img {
  max-width: 150px;
  padding-top: 20px;
}

//
// Forms
//

input.masked {
  -webkit-text-security: disc;
}

h2.required:after {
  content: "*";
  color: theme-color("danger");
}

//
// Verifications
//

form.vault-threshold-change label {
  margin-right: 2em;
}

form.vault-threshold-change .input-group-append {
  margin-right: 2em;
}

code {
  color: black;
}

// Put back some missing base styles that make things not gross, only on admin pages
.admin-page {
  h1,
  h2,
  h3,
  p {
    margin-bottom: 1rem;
  }
}
