@import "src/assets/scss/theme.scss";
.content {
  height: 100%;
  width: 100%;
  min-height: 300px;
  //max-height: 500px;
  min-width: 300px;
  max-width: calc(100vw - 49px - 2rem);

  @media (min-width: $breakpoint-xs) {
    min-height: 300px;
    max-height: 1000px;
    min-width: 300px;
    max-width: 500px;
  }
  @media (min-width: $breakpoint-sm) {
    min-height: 450px;
    max-height: 1000px;
    min-width: 550px;
    max-width: 600px;
  }

  padding: 3rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.fullContent {
  max-width: 100% !important;
  width: 100% !important;
}

.mdContent {
  min-height: 18rem;
  max-height: 50rem;
  min-width: 26rem;
  max-width: 29rem;
  padding: 1rem 1rem;
}

.smContent {
  min-height: 17rem;
  max-height: 50rem;
  min-width: 17rem;
  max-width: 22rem;
  padding: 1.5rem 0.5rem;
}

.titleBase {
  margin-top: 0;
  margin-bottom: 0.8rem;
  line-height: 1.5;
  text-transform: unset;
  font-weight: 600;
  @media (min-width: $breakpoint-sm) {
    font-weight: 400;
  }
}

.title {
  composes: titleBase;
  @include h950;
}

.smTitle {
  composes: titleBase;
  font-size: 1.3rem;
}

.subtitle {
  margin-top: 0;
  margin-bottom: 0.3rem;
  line-height: 1.5;
  text-transform: unset;
  letter-spacing: 1px;
  font-size: 1.3rem;
  font-weight: 600;
  @media (min-width: $breakpoint-sm) {
    font-weight: 400;
  }
}

.explanation {
  font-size: 1rem;
  margin-bottom: 0;
}

.response {
  margin: 1rem 0.5rem 0.5rem;
}

.bottomErrorMessage {
  color: $red-700;
  font-size: 0.75rem;
  text-align: center;
  margin-top: 1.25rem;
  min-height: 1em;
  line-height: 1em;
  margin-bottom: 0;
}
