@import "src/assets/scss/theme.scss";

.content {
  max-width: 23rem;
  @media (min-width: $breakpoint-sm) {
    max-width: 28rem;
  }
}

.gridItem {
  @media (max-width: $breakpoint-sm) {
    padding: 6px;
  }
}

.text {
  font-size: 0.925rem;
}

.primaryInstruction {
  composes: text;
  font-weight: 600;
  line-height: 1rem;
  text-align: justify;
}

.helpText {
  font-size: 0.875rem;
  text-align: justify;
}

.imageContainer {
  width: 55%;
  margin-right: auto;
  margin-left: auto;
}

.image {
  width: 100%;
  height: 100%;
}

.prompt {
  composes: primaryInstruction;
}

.prompt:hover {
  cursor: pointer;
}

.linkLike {
  color: $primary-700;
}

.linkLike:hover {
  color: $primary-700;
  text-decoration: underline;
}
