@import "src/assets/scss/theme.scss";

.trigger {
  cursor: pointer;
}

.divider {
  width: 100%;
  height: 0.125rem;
  background-color: $gray-100;
  margin: 0.5rem 0;
}

.menu {
  min-width: 13rem;
}

.item {
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.75rem 1.75rem 0.75rem 1rem;
  text-align: left;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: color 0.5s ease;
  color: $gray-600;

  &:hover {
    color: black;
  }

  > span:first-child {
    justify-content: center;
    width: 1rem;
    margin-right: 1rem;
  }

  &:first-child {
    padding-top: 1.25rem;
  }

  &:last-child {
    padding-bottom: 1.25rem;
  }
}
