@import "src/assets/scss/theme.scss";

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  margin-bottom: 1.5rem;
}

.nextStepsList {
  padding-left: 0.5rem;
  @include text-500;
  margin-bottom: 2.25rem;
}

.closeButton {
  padding: 0.25rem;
  position: relative;
  top: -0.5rem;
  right: -0.5rem;
  color: $gray-300;
  align-self: flex-start;
}
