@import "src/assets/scss/theme.scss";

$input-height: 69px;

.labelContainer {
  @include text-400;
  color: $primary-700;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.labelButtonContainer {
  & + & {
    border-left: 2px solid $gray-300;
  }
}

.labelButton {
  margin: 0 0.25rem;
  cursor: pointer;

  &_disabled {
    color: $gray-300;
    cursor: unset;
  }
}

.switchButton {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.75rem;
  background-color: $primary-700;
  border-radius: 0 4px 4px 0;
  height: $input-height;
  max-height: $input-height;
  cursor: pointer;
  color: $white;
}

.tickerIcons {
  display: flex;
  flex-direction: column;
}

.tickerIcon {
  @include h200;
  font-size: 12px;
  text-align: center;
  margin: 0;
  padding: 0;
  color: $white;

  &_disabled {
    opacity: 0.35;
  }
}

.swapVertRoot {
  padding: 0;
}

.inputAdornment {
  margin-right: 0;
}

.inputAdornmentLabel {
  font-size: 28px;
  font-weight: 500;
  color: $primary-700;
  margin: 0;

  @media (min-width: $breakpoint-sm) {
    font-size: 30px;
  }

  &_error {
    color: $red-700;
  }
}

.inputField {
  font-size: 28px;
  color: $primary-700;
  font-weight: 500;
  padding: 0;
  height: $input-height;

  @media (min-width: $breakpoint-sm) {
    font-size: 30px;
  }

  &_error {
    color: $red-700;
  }
}

.adornedEnd {
  padding-left: 10px;
  padding-right: 0;
  background-color: $primary-100;
  border-radius: 4px;

  &_disabled {
    background-color: $gray-100;
  }
}

.details {
  display: flex;
  justify-content: space-between;
  margin-top: 0.25rem;
}
