@import "src/assets/scss/theme.scss";

.checkIcon {
  font-size: 27px;
  padding: 0px;
  margin-top: 0;
}

.title {
  @include h900;
  font-size: 24px;
  color: $gray-800;
  margin-bottom: 0;
  margin-right: 9px;
}

.successfulText {
  @include text-600;
  font-size: 14px;
  margin-top: 24px;
}

.confirmContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.infoIcon {
  margin-left: 6px;
}
