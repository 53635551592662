.footer {
  font-size: 14px !important;
  a {
    color: $white;
  }
  h1 {
    font-size: 18px;
    margin-top: 5em;
    text-align: left;

    &:after {
      content: " ";
      display: block;
      border: 1px solid;
      margin-top: 0.5em;
      margin-bottom: 1.5em;
      width: 75%;
    }
  }
  img {
    margin-top: 5em;
    height: 90px;
  }
}

// Removed along with bootstrap
$spacer: 1rem;

#page_footer {
  background: $gray-800;
  color: $white;
  min-height: 10rem;
  padding-top: $spacer * 5;
  padding-bottom: $spacer * 5;
  position: relative;
  overflow: hidden;
  & > img {
    // footer background responsive img
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom right;
    z-index: -1;
  }
  .logo {
    img {
      height: 4rem;
    }
  }
  .copyright {
    font-size: 8pt;
    color: change-color($color: $gray-800, $saturation: 35%, $lightness: 60%);
    margin-top: 1rem;
  }
  .socials {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    a + a {
      margin-left: 0.5rem;
    }
  }
  ul {
    li {
      margin-bottom: 0.75rem;
      a {
        color: $white;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  #unchained-address,
  #unchained-phone a,
  #unchained-email a {
    color: $gray-400;
    font-size: 0.75rem;
    line-height: 1rem;
    margin-top: 0.5rem;
  }
  #unchained-phone,
  #unchained-email {
    margin-top: 0.5rem;
  }
}
