// Removed along with bootstrap
$spacer: 1rem;

// Mark certain <label> tags as required.
.form-group.required .control-label:after {
  content: "*";
  color: theme-color("danger");
}

label.has-float-label {
  display: block;
  position: relative;
  padding-top: $spacer * 0.75;

  .form-control {
    background-color: $primary-100;
    color: $white;
    & + * {
      position: absolute;
      left: 0.75em;
      top: 0;
      transform: translateY(-1.25em);
      transition: transform 0.15s;
      color: $white;
    }
    &:placeholder-shown:not(:focus) + * {
      transform: translateY(0.45em);
      color: transparent;
    }
    &::placeholder {
      color: $white;
    }
    &:focus::placeholder {
      color: $primary-100;
    }
  }
}

.form {
  &__input {
    &--number-no-spinner {
      -moz-appearance: textfield;
      box-shadow: none;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    &--disabled {
      background-color: transparent;
      color: $gray-800 !important;
    }
  }
}

.invalid-feedback {
  display: block;
}
