@import "src/assets/scss/theme.scss";

.card {
  padding: 0 2.5rem;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.signingWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (min-width: $breakpoint-lg) {
    padding: 0 1rem;
  }
}

.stepper {
  flex: 0 1 auto;

  > div {
    padding: 1.5rem;
  }
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (min-width: $breakpoint-lg) {
    flex-direction: row;
  }
}

.buttons {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
}

.transactionDetails {
  @media (max-width: $breakpoint-lg) {
    padding: 0 2.5rem;
    margin-top: 1rem;
  }
}

.icon {
  &__active,
  &__completed {
    color: $primary-700 !important;
  }
}
