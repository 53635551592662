.cardHeading {
  text-align: center;
}

.transactionIdLabel {
  font-weight: 400;
  font-size: 14.7px;
  margin-bottom: 0;
  margin-top: 2px;
}
