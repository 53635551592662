$background-item-z-index: -1;
$top-bar-z-index: 300;
$nav-container-z-index: 1030;
$nav-bar-z-index: 1200;
$app-overlay-z-index: 1201;
$trading-highlight-z-index: 1202;
$drop-down-menu-z-index: 1203;
$slide-out-z-index: 1299;
$modal-backdrop-z-index: 1300;
$flash-z-index: 999999;

:export {
  background-item-z-index: $background-item-z-index;
  top-bar-z-index: $top-bar-z-index;
  nav-bar-z-index: $nav-bar-z-index;
  app-overlay-z-index: $app-overlay-z-index;
  trading-highlight-z-index: $trading-highlight-z-index;
  modal-backdrop-z-index: $modal-backdrop-z-index;
  slide-out-z-index: $slide-out-z-index;
  flash-z-index: $flash-z-index;
  drop-down-menu-z-index: $drop-down-menu-z-index;
}
