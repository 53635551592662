@import "src/assets/scss/theme.scss";

@mixin circle($circle-size) {
  width: $circle-size;
  height: $circle-size;
  position: relative;
  display: inline-block;
  vertical-align: top;
}

@mixin background($circle-size) {
  width: $circle-size;
  height: $circle-size;
  border-radius: 50%;
  background: $green-600;
  position: absolute;
}

@keyframes checkMarkDrawLg {
  $circle-size: 5.875rem;
  $check-height: calc(#{$circle-size} / 2);
  $check-width: calc(#{$check-height} / 2);

  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}

@keyframes checkMarkDrawMd {
  $circle-size: 3.87rem;
  $check-height: calc(#{$circle-size} / 2);
  $check-width: calc(#{$check-height} / 2);

  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}

@keyframes checkMarkDrawSm {
  $circle-size: 2.5rem;
  $check-height: calc(#{$circle-size} / 2);
  $check-width: calc(#{$check-height} / 2);

  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}

@mixin checkMark($circle-size) {
  $check-height: calc(#{$circle-size} / 2);
  $check-width: calc(#{$check-height} / 2);
  $check-thickness: calc(#{$circle-size} / 14);
  opacity: 1;
  height: $check-height;
  width: $check-width;
  transform-origin: left top;
  border-right: $check-thickness solid $white;
  border-top: $check-thickness solid $white;
  border-radius: 2.5px !important;
  content: "";
  left: calc(#{$check-width} * 0.93);
  top: calc(#{$check-height} * 1.05);
  position: absolute;
}
.checkmark {
  border-radius: 5px;
}

.success {
  letter-spacing: 0.3px;
  text-transform: unset;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
}

.smSuccess {
  .smCheckmark:after {
    @include checkMark(2.5rem);
  }
  .smBackground {
    @include background(2.5rem);
  }
  .smCircle {
    @include circle(2.5rem);
    margin-top: 0.5rem;
  }

  .smDraw:after {
    animation-name: checkMarkDrawSm;
    animation-delay: 100ms;
    animation-duration: 1s;
    animation-timing-function: ease;
    transform: scaleX(-1) rotate(180deg + -45deg);
    animation-fill-mode: forwards;
  }
}
.mdSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;

  .mdCheckmark:after {
    @include checkMark(3.875rem);
  }
  .mdBackground {
    @include background(3.875rem);
  }
  .mdCircle {
    @include circle(3.875rem);
    margin-top: 0.5rem;
  }

  .mdDraw:after {
    animation-name: checkMarkDrawMd;
    animation-delay: 100ms;
    animation-duration: 1s;
    animation-timing-function: ease;
    transform: scaleX(-1) rotate(180deg + -45deg);
    animation-fill-mode: forwards;
  }
}

.lgSuccess {
  $circle-size: 5.875rem;

  .lgCheckmark:after {
    @include checkMark($circle-size);
  }
  .lgBackground {
    @include background($circle-size);
  }
  .lgCircle {
    @include circle($circle-size);
    margin-top: calc(#{$circle-size} * 0.43);
  }

  .lgDraw:after {
    animation-name: checkMarkDrawLg;
    animation-delay: 100ms;
    animation-duration: 1s;
    animation-timing-function: ease;
    transform: scaleX(-1) rotate(180deg + -45deg);
    animation-fill-mode: forwards;
  }
}
