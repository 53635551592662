@import "src/assets/scss/theme.scss";
.dialogBox {
  margin: 8px;

  @media (min-width: $breakpoint-sm) {
    margin: 8px 8px 8px 58px;
  }

  @media (min-width: $breakpoint-md) {
    margin: 48px 48px 48px 228px;
  }
  @media (min-width: $breakpoint-xl) {
    margin-left: 298px;
  }
}

.closeButton {
  padding: 5px;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 100;
}
