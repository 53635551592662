@import "src/assets/scss/theme.scss";
$breakpoint-amountInstructions: 440px;

.button {
  font-size: 14px;
}

.buttonContainer {
  border-radius: 8px;
  margin-top: 2rem !important;

  @media (min-width: $breakpoint-sm) {
    height: 48px;
  }
}

.amountSubTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.25rem;
}

.footerText {
  @include text-300;
  font-size: 12px;
  margin-bottom: 0;
  max-width: 450px;
  color: $gray-600;
  margin-top: 1.75rem;
}

.container {
  position: relative;
}

.showModal {
  display: block;
  position: relative;
}

.amountInstructionsContainer {
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-amountInstructions) {
    flex-direction: row;
  }
}

.instructionsButton {
  margin-left: 0.5rem;
  font-weight: 500;
  font-size: 12px;
}

.closeModalButton {
  top: 0 !important;
  right: 0 !important;
}
