@import "src/assets/scss/theme.scss";

.container {
  padding-top: 16px;
  justify-content: flex-start;
}

.smTitle {
  font-size: 1.3rem;
  margin-bottom: 0;
  margin-top: 0;
}

.warningPrompt {
  font-weight: 300;
  font-size: 0.85rem;
}

.trezorMessage {
  font-size: 0.85rem;
  line-height: 1.2;
}

.trezorReminder {
  margin-bottom: 0.3rem;
  display: inline-block;
}

.coldcardConfigWarning {
  composes: warningPrompt;
  margin-top: 1.5rem;
}

.coldcardConfigExpanded {
  margin-top: 0.5rem;
}

.coldcardConfigContent {
  composes: warningPrompt;
  padding-top: 0.4rem;
  font-size: 0.8rem;
  line-height: 1.2;
  text-align: justify;
}

.coldcardConfigButton {
  padding-top: 0.4rem;
  font-size: 0.8rem;
  line-height: 1.2;
}

.ledgerFeeWarning {
  composes: warningPrompt;
  margin-top: 1.5rem;
}

.ledgerFeeExpanded {
  margin-top: 0.5rem;
  margin-bottom: -8px;
}

.ledgerFeeWarningContent {
  composes: warningPrompt;
  padding-top: 0.2rem;
  font-size: 0.8rem;
  line-height: 1.2;
  margin-bottom: -8px;
  text-align: justify;
}

.ledgerWarningSpacer {
  padding-top: 0.2rem;
  display: inline-block;
}

.trezorBip32 {
  composes: warningPrompt;
  margin-top: 1.5rem;
}

.trezorBip32Expanded {
  composes: warningPrompt;
  margin-top: 0.5rem;
  margin-bottom: -8px;
  transition: margin-top 100ms linear;
}

.trezorBip32Content {
  margin-bottom: 0;
  margin-top: 0.2rem;
}

.bold {
  font-weight: 600;
}

.clickableText {
  color: $primary-700;
  padding: 0;
  cursor: pointer;
}

.clickableText:hover {
  color: $primaryHover;
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-color: rgba($primary-700, 0.4);
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  min-height: 12rem;
  margin-bottom: auto;
  padding: 0 0.5rem;
}
