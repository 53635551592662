@import "src/assets/scss/theme.scss";

.mainMessage {
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  min-height: 12rem;
  padding: 0 0.5rem;
}

.errorMessage {
  font-size: 0.8rem;
  color: $red-700;
  line-height: 1;
  height: 1.1875rem;
}

.warningPlaceholder {
  height: 2.75rem;
}
