@import "src/assets/scss/theme.scss";

.container {
  width: 25rem;
}

.bankAccount {
  width: 100%;
  margin-top: 0.5rem;
}

.bankAccountSelect {
  width: 100%;
  margin-bottom: 0.25rem;
}

.selectOption {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.accountName {
  margin: 0 0.3125rem 0 0;

  &_inactive {
    color: $gray-300;
  }
}

.accountNumber {
  @include text-300;

  &_inactive {
    color: $gray-300;
  }
}

.helperTextContainer {
  display: flex;
}

.linkText {
  color: $primary-700;
  cursor: pointer;
}

.tooltipIcon {
  color: $gray-400;
  height: 1.125rem;
  width: 1.125rem;
  margin-left: 0.25rem;
  cursor: default;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
