@import "src/assets/scss/theme.scss";

.contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 23rem;
  margin-top: 32px;

  @media (min-width: $breakpoint-sm) {
    width: 24rem;
  }
  //margin-left: 25px;
  background-color: $white;
  //margin-top: 25px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 1.3rem;
  margin-right: 15px;
  margin-bottom: 0;
  text-align: center;
}

//.arrowSide {
//  position: relative;
//  font-size: 10px;
//  width: 3em;
//  height: 3em;
//  left: 0;
//  margin-bottom: 1em;
//  &:after {
//    content: "";
//    position: absolute;
//    box-sizing: border-box;
//    width: 0;
//    height: 0;
//    border: 1em solid black;
//    border-color: transparent transparent white white;
//    margin: auto;
//    top: -1em;
//    margin-left: 25px;
//    //margin-bottom: 1em;
//    transform-origin: 0 0;
//    transform: rotate(45deg);
//    box-shadow: -1px 2px 2px 0px rgba(0, 0, 0, 0.14), -1px 2px 1px -3px rgba(0, 0, 0, 0.12), 9px 0px 0px 0px transparent, 0 -9px 0px 0px transparent;
//
//  }
//}

.arrowUp {
  position: relative;
  font-size: 14px;
  top: 10px;
  left: 0rem;
  width: 3em;
  height: 1em;
  box-size: border-box;
  margin-left: 20px;
  margin-bottom: -1em;

  &::after {
    content: "";
    position: absolute;
    box-sizing: border-box;
    width: 0;
    height: 0;
    border: 1em solid black;
    border-color: $white transparent transparent white;
    margin: auto;
    top: 0.2em;
    //margin-left: 25px;
    //margin-bottom: 1em;
    transform-origin: 0 0;
    transform: rotate(45deg);
    box-shadow: -2px -2px 1px -1px rgba(0, 0, 0, 0.1);
  }
}

.popper {
  z-index: 1;
}

.keyContent {
  flex-direction: column;
  position: relative;
  overflow: auto;
}

.keyHolder {
  //text-align: center;
  justify-content: center;
  height: inherit;
  min-height: 40rem;
  max-height: 40rem;

  //overflow: scroll;
  //
  //@media(min-width: $breakpoint-md) {
  //  flex-direction: column;
  //  padding: 0 0 150px;
  //  margin-top: auto;
  //  margin-bottom: auto;
  //  justify-content: flex-start;

  //max-height: 80%;
  //}
}
