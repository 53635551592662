@import "src/assets/scss/theme.scss";

.content {
  width: 100%;
  min-height: 300px;
  min-width: 300px;
  max-width: calc(100vw - 49px - 2rem);
  padding: 40px 16px;

  @media (min-width: $breakpoint-xs) {
    min-height: 300px;
    // The line below was causing issues when displaying large numbers of
    // account keys when creating a vault.
    // max-height: 1000px;
    min-width: 300px;
    max-width: 500px;
    padding: 32px 16px;
  }
  @media (min-width: $breakpoint-sm) {
    min-height: 450px;
    // The line below was causing issues when displaying large numbers of
    // account keys when creating a vault.
    // max-height: 1200px;
    min-width: 550px;
    max-width: 600px;
    padding: 3rem 2.5rem;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title {
  @include h950;
  margin-top: 0;
  margin-bottom: 0.8rem;
  text-transform: unset;
}

.subtitle {
  @include h700;
  margin-top: 0;
  margin-bottom: 0.8rem;
}

.response {
  margin: 1rem 0.5rem 0.5rem;
}

.bottomErrorMessage {
  color: $red-500;
  font-size: 0.75rem;
  text-align: center;
  margin-top: 20px;
  min-height: 1em;
  line-height: 1em;
}
